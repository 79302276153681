// extracted by mini-css-extract-plugin
export var ArtistDescription = "LeeQuinones-module--ArtistDescription--+j4-B";
export var ArtistInfos = "LeeQuinones-module--ArtistInfos--67ChS";
export var ButtonWrapper = "LeeQuinones-module--ButtonWrapper --tCLC6";
export var CardWrapper = "LeeQuinones-module--CardWrapper--iOHws";
export var CarrouselWrapper2 = "LeeQuinones-module--CarrouselWrapper2---mHjb";
export var Citations = "LeeQuinones-module--Citations--bg3zn";
export var DescriptionWrapper = "LeeQuinones-module--DescriptionWrapper--s0-T2";
export var ImageWrapper = "LeeQuinones-module--ImageWrapper--zBEWy";
export var LinkWrapper = "LeeQuinones-module--LinkWrapper--0Xicv";
export var MobileProtrait = "LeeQuinones-module--MobileProtrait--ygMFi";
export var More = "LeeQuinones-module--More--xAiSA";
export var MoreButton = "LeeQuinones-module--MoreButton--Lh9Bo";
export var NameWrapper = "LeeQuinones-module--NameWrapper--HaiHw";
export var PdpWrapper = "LeeQuinones-module--PdpWrapper--ySLN8";
export var PhotosWrapper = "LeeQuinones-module--PhotosWrapper--eRzGf";
export var ProfilWrapper = "LeeQuinones-module--ProfilWrapper--dMuow";
export var TitleWrapper = "LeeQuinones-module--TitleWrapper--oDuj4";
export var Wrapper = "LeeQuinones-module--Wrapper--DyWTR";