import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./LeeQuinones.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import LeeQuinonesPng from "../../../../res/quinones/portrait.png"
import LeeQuinonesPng1 from "../../../../res/Photos site/Lee quinones/futura lee.jpg"
import LeeQuinonesPng2 from "../../../../res/Photos site/Lee quinones/Lee quinones graff.jpg"
import LeeQuinonesPng3 from "../../../../res/Photos site/Lee quinones/Lee Quinones.jpg"
import LeeQuinonesPng4 from "../../../../res/Photos site/Lee quinones/lee_quinones_studio by lee quinones.jpg"
import LeeQuinonesPng5 from "../../../../res/Photos site/Lee quinones/Martha-Cooper-LEE-handball-court-1983.png"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/quinones/past-present-1.jpg"
import PastPresent2 from "../../../../res/quinones/past-present-2.jpg"
import PastPresent3 from "../../../../res/quinones/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "lee-quinones",
  name: "Lee Quinones",
  description: 
  "Lee Quiñones, born in 1960 in Ponce, Porto Rico, was raised in New York on the Lower East-Side. Quiñones painted his first subway cars at the young age of 14 and quickly became a major figure of New York Graffiti. He became the recognizable author of provocative socio-political compositions mixing text and image, imposing himself as an undeniable pillar of Post-Graffiti and contemporary art of the 1990’s. He participated, alongside Lady Pink, in the film Wild Style, 1982, directed by Charlie Ahearn, which will become an iconic fictional testimony of New York Hip Hop culture in the 80’s. He was also part of the historical exhibition Post-Graffiti, at the Sidney Janis Gallery in 1983. Lee was one of the first artists to present the soul of Graffiti to Europe, thanks to his duo exhibition with Fab 5 Freddy at the Galerie Medusa in Rome (1979). His works are preserved in some of the most important collections : the Whitney Museum of Art, the MOMA and the Brooklyn Museum in New York as well as the Groninger Museum in Groningen, the Boijman van Beuningen Museum in Rotterdam, in the Netherlands, and at the Ludwig Forum for International Art in Aachen, Germany.",
  pdp: LeeQuinonesPng,
  alt_pdp: "Photo de profil de Lee Quinones.",
  photos: [
    { src: PastPresent1, name: "Lee Quinones" },
    { src: PastPresent2, name: "Lee Quinones" },
    { src: PastPresent3, name: "Lee Quinones" },
  ],
  citations: [],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
};

const LeeQuinones = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>LEE QUINONES <h2 style={{paddingLeft: "16px"}}>(Lee George Quinones - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1960</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Lee Quinones, Black and Blue, Charlie James Gallery, Los Angeles, California, USA, June 12th - August 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Ori Carini & Lee Quinones, Unfolding, HOWL! Happening, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- If These Walls Could Talk, Charlie James Gallery, Los Angeles, USA, January 12th - March 23rd.</p>
          <p>- Language Barriers, James Fuentes, New York, USA, November 6th, 2019 - January 12th, 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Lee Quinones, Nicole Klagsbrun Inc., New York, USA , May 2nd - June 7th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Taking the Fifth, Salon Bob, New York, USA. - June 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Truth and Consequences, Galleria Il Trifoglio Nero, Genoa, Italy, April 18th - May 23rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Lee Quinones, MoMA PS1, New York, USA, June 24th - September 24th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Prelude, Galeria Candela, San Juan, Puerto Rico.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- A Day In The Life, Buck15, Miami, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Space & Time, Rome Arts, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Ghetto Samurai, Salon Bob, New York, USA.</p>
          <p>- Drawings, Then And Than, Rome Arts Gallery, New York, USA.</p>
          <p>- Casual Get Over, Lot 61, New York, USA.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Securing the Requiem, Private Residence, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Nuevo, Espace Romero, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Windows, Livestock Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Straight Forward, Urban Classics L.T.D, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- New Horizons, Riverside Studios, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Rapid Enamellist, Barbara Gladstone Gallery, New York, USA.</p>
          <p>- Rudolf Zwirner Gallery, Cologne, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Off The Streets, Contemporary Arts Center, Cincinnati, Ohio, USA.</p>
          <p>- Barbara Farber Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Rusto-LEE-um, Fun Gallery, New York, USA.</p>
          <p>- Barbara Gladstone Gallery, New York, USA.</p>
          <p>- Barbara Farber Gallery, Amsterdam, The Netherlands.</p>
          <p>- Lisson Gallery, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- The Third Phase, White Columns Gallery, New York, USA.</p>
          <p>- Galleria Paolo Seno, Milano, Italy.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- The Cool and Cold: Painting from US and the USSR 1960 - 1990, Gropius Bau, Berlin, Germany, September 24th, 2021 - January 9th, 2022.</p>
          <p>- From The Archives: White Columns & 112 Green Street, 1970-2021..., White Columns, New YORK, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Writing the Future: Basquiat and the Hip Hop Generation, MFA, Boston, USA, October 18th, 2020 - July 25th, 2021.</p>
          <p>- YOUR HOME IS MINE, Steinberg Museum of Art, Brookville, New York, USA</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Elements of Style: New York City Street Culture in the 1980s, Seoul Museum of Art, Seoul, South Korea, December 21st, 2018 - March 20th, 2019.</p>
          <p>- Henry Chalfant: Art Vs.Transit, The Bronx Museum, New York, USA, September 25th, 2019 - March 8th, 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- The Zeitgeist, Maier Museum of Art, Lynchburg, Virginia, USA, May 13th - July 29th.</p>
          <p>- Shoot the Pump, Bullet Space, New York, USA, September 13th - November 30th.</p>
          <p>- Open Spaces Biennial, Kansas City, Missouri, USA.</p>
          <p>- Beyond the Streets, Los Angeles, USA, July 17th - August 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Cross the Streets, Museum of Contemporary Art Rome, Rome, Italy, May 7th - October 1st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Glamourous Graffiti, Nassau County Museum of Art, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Unrealism, Gagosian and Jeffrey Deitch, Miami, USA, December 1st - 15th.</p>
          <p>- Le Pressionnisme 1970 - 1990, The Masterpieces of Graffiti Painting, Paris, France, March 12th - September 13th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- The Other Americans, Ludwig Forum, Aachen, Germany, March 17th, 2013 - October 26th, 2014.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Art in The Streets, Geffen Center at the Museum of Contemporary Arts, Los Angeles, USA, April 17th - August 8th.</p>
          <p>- El Museo Bienal: The S Files, El Museo del Barrio, New York, USA, June 14th, 2010 - January 8th, 2011.</p>
          <p>- Looking at Music 3.0, Museum of Modern Art, New York, USA, February 16th - May 30th.</p>
          <p>- Hyperreal: Art In America Around 1970 , Ludwig Forum for International Art, Aachen, Germany, March 13th - June 20th.</p>
          <p>- Next Wave, Art Brooklyn Academy of Music, Brooklyn, USA, September 21st - December 15th.</p>
          <p>- Nose Job, Eric Firestone Gallery, East Hampton, New York, USA, July 15th - August 21st.</p>
          <p>- Three Kings, Subliminal Gallery, Los Angeles, USA, September 17th - October 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Sisley Art Project, Andy Warhol Museum, Pittsburgh, Pennsylvania, USA, October 23rd - November 3rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Art, Archives and Activism: Martin Wong’s Downtown Crossings, Asian Pacific American Institute, New York University, New York, USA, March 6th - December 18th.</p>
          <p>- Whole In the Wall, Galerie Helenbeck, New York, USA, May 29th - June 27th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Downtown 81, Du Festival D’Automne, Paris, France, October 21st - November 21st.</p>
          <p>- VH1/ Powerhouse Present : No Sleep 'Till Brooklyn, Powerhouse Gallery, Brooklyn, USA, September 6th - November 19th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Public Access: Ricky Powell Photographs; 1985-1995, Powerhouse Gallery, Brooklyn, USA.</p>
          <p>- Style & Design from A-Z Hosted by Complex Magazine, Latin Collector Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- East Village USA, New Museum of Contemporary Art, New York, USA.</p>
          <p>- New Works Lee Quinones & Pedro Gomez, ROME Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Bright Lights Big City, David Zwirner, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Yellow’s Spring Party, Hotel Astor, Miami, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Hip-Hop: Roots, Rhymes & Rage, Brooklyn Museum of Art, Brooklyn, USA.</p>
          <p>- Summer Show, DFN Gallery, New York, USA.</p>
          <p>- Neo Graffiti Project, Agnes B., New York, USA.</p>
          <p>- Five From The Underground, Steven Vail Galleries, Des Moines, Iowa, USA.</p>
          <p>- Small Stuff, AZ/NY Galleries, Scottsdale, Arizona, USA.</p>
          <p>- Xhibition Transition, Quantum Leap, Chicago, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- NY2K, El Bohio Cultural Community Center, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Contents Under Pressure, Tramshed, London, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Up From The Underground, Lineage Gallery, Chicago, USA.</p>
          <p>- Expanded Exhibition, Franklin Loft, Chicago, USA.</p>
          <p>- Futura-Lee-Stash-Russ, Surr Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- 1st Annual Holiday Exhibition, Mary Anthony Galleries, New York, USA.</p>
          <p>- Doggy Style, Rick Prol Studio, New York, USA.</p>
          <p>- 25 Contemporary Artists From New York, Yuki-Sun International, Nagoya, Japan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Aerosoul, The Paterson Museum, Paterson, New Jersey, USA.</p>
          <p>- Urban Expressionism, Hudson Guild Gallery, New York, USA.</p>
          <p>- Influential Works in the 90’s, The Hobbs Gallery, New York, USA.</p>
          <p>- Spray Can Symposium, Sixth Congress Gallery, Tucson, Arizona, USA.</p>
          <p>- The New Groninger Museum, Groningen, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- In Transit, The New Museum of Contemporary Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Graffiti, Klarsfeld Perry Gallery, New York, USA.</p>
          <p>- Poetry In Motion, Beret International Gallery, Chicago, USA.</p>
          <p>- Graffiti Coming from the Subway, Groninger Museum, Groningen, The Netherlands.</p>
          <p>- Graffiti Art: American and French, Museum of National Monuments, Paris, France.</p>
          <p>- Malcolm X: Portrait of a Legacy, Aaron Davis Hall at City College, New York, USA.</p>
          <p>- Education 92, Absolut Vodka, Interview Magazine and Urban Architecture, Inc. A Charitable Auction for D.I.F.F.A (Design Industries Foundation For AIDS), Detroit, Michigan, USA.</p>
          <p>- Your House Is Mine, Exit Art, New York, USA.</p>
          <p>- The Power of the City, City of Power, Whitney Museum of American Art, New York, USA.</p>
          <p>- Legacy, Empire Gallery, New York, USA.</p>
          <p>- Rapid Enamellist, David Leonardis Gallery, Chicago, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Hip-Hop Nation, Real Art Ways, Traveling Exhibition, Hartford, CT ; Liverpool Gallery, Liverpool, UK.</p>
          <p>- Inner City, Outer Limits, Kenkeleba Gallery, New York, USA.</p>
          <p>- World Disorder, Cultural Space, New York, USA.</p>
          <p>- Images of Labor, Gallery 1199, New York, USA.</p>
          <p>- Urban Classics Gallery, Brooklyn, USA.</p>
          <p>- Graffiti Movement, Colleen Greco Gallery, Suffern, USA.</p>
          <p>- Painting in the East Village, Vacuum Gallery, New York, USA.</p>
          <p>- Post Graffiti, Federal Reserve Board Building, Washington, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- High-Low Art, Museum of Modern Art, New York, USA.</p>
          <p>- Your House Is Mine, Bullet Space, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Boogie Down: Miracle on the Deuce, Whitney Museum of American Art at Phillip Morris, New York, USA.</p>
          <p>- Graffiti Retro, Stadtische Galerie, Goppingen, The Netherlands.</p>
          <p>- Graffiti Retro, Museum of American Graffiti, New York, USA.</p>
          <p>- Golden Opportunity, Nest Foundation, Leo Castelli, New York, USA.</p>
          <p>- Friends To Assist Puerto Rico, CommonWealth of Puerto Rico Building, New York, USA.</p>
          <p>- The Forest Show, Red Square, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Contemporary Art Auction #2, El Bohio Community and Cultural Center, New York, USA.</p>
          <p>- Art for Eldridge, The Mobile Art Show, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Melee Archives, Franklin Furnace, Brooklyn, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Miss Liberty Show, Barney’s New York, USA.</p>
          <p>- Arts and Leisure, Group Material, New York, USA.</p>
          <p>- Liberty and Justice, Alternative Museum, New York, USA.</p>
          <p>- Kings of the Spray, Flow Ace Gallery, Los Angeles, USA.</p>
          <p>- Celebration VII: Seventh Heaven, New Museum at the World Trade Center, New York, USA.</p>
          <p>- New York Underground, Institute for North American Studies, Barcelona, Spain.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Group Material, Biennial Exhibition, Whitney Museum of American Art, New York, USA.</p>
          <p>- Forecast: Images of the Future, Kenkeleba Gallery, New York, USA.</p>
          <p>- Rain Dance, Benefit for the U.S. Committee for UNICEF, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Rapid Enamel, Renaissance Society at the University of Chicago, Chicago, USA.</p>
          <p>- Frontier Art, Galleria D’Arte Moderna, Bologna, Italy.</p>
          <p>- Classical American Graffiti Artist, Galleria Thomas, Munich, Germany.</p>
          <p>- Painting and Sculpture Today, Indianapolis Museum, Indianapolis, Indiana, USA.</p>
          <p>- The New Portrait, Institute for Art and Urban Resources at P.S.1, Long Island City, - Chill Out New York, Kenkeleba Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Post Graffiti, Sidney Janis Gallery, New York, USA.</p>
          <p>- Portrait for the 80’s, Protetch McNeil, New York, USA.</p>
          <p>- Six Latin Artists, Gallery 1199, New York, USA.</p>
          <p>- The Comic Art Show, Whitney Museum of American Art, New York, USA.</p>
          <p>- From the Streets, Greenville County Museum of Art, Greenville, South Carolina, USA.</p>
          <p>- Graffiti, Museum Boijmans Van Beuningen, Rotterdam, Netherlands; Galleria Rudolf Zwirner, Cologne, Germany.</p>
          <p>- Style Wars, film premier Museum of Modern Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Documenta #7, Kassel, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Beyond Words, Mudd Club, New York, USA.</p>
          <p>- New York/New Wave, Institute for Art and Urban Resource at P.S.1, Long Island, New York, USA.</p>
          <p>- The Drawing Show, Mudd Club, New York, USA.</p>
          <p>- Events, The New Museum, New York, USA.</p>
          <p>- Stations Of The Elevated, film premier Museum of Modern Art, New York, USA.</p>
          <p>- New York Film Festival, Lincoln Center, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- Graffiti Art Success for America, Fashion Moda, Bronx, New York, USA.</p>
          <p>- Times Square Show, Midtown Manhattan, New York, USA, June.</p>
          <p>- Artist Invite Artist, Fashion Moda, Bronx, New York, USA.</p>
          <p>- New Painting: The Third Phase, Fab 5 Freddy, Lee Quiñones, White Columns, Downtown Manhattan, New York, USA, September 30th - October 11th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1979</p>
          <p>- The Fabulous Five, Galleria La Medusa, Rome, Italy.</p>
          <p>- Lee Quinones/Jean Michel Basquiat/Fred Brathwaite, Canal Zone, New York, USA.</p>
          <p>- The Fab 5ive, Unique Clothing Warehouse, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PERMANENT COLLECTIONS</p>
          <br />
          <p>- Museum of Modern Art, New York, USA.</p>
          <p>- Whitney Museum of Modern Art, New York, USA.</p>
          <p>- Museum Boijman Van Beuningen, Rotterdam, Netherlands.</p>
          <p>- Groninger Museum, Groningen, Netherlands.</p>
          <p>- Brooklyn Museum, New York, USA.</p>
          <p>- Museum of the City of New York, New York, USA.</p>
          <p>- Blanton Museum of Art, Austin, USA.</p>
          <p id="end">- Ludwig Forum for International Art, Aachen, Germany.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default LeeQuinones;